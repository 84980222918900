<script setup>
const props = defineProps(["color"]);
</script>

<template>
  <div class="sticky-bg">
    <video loop autoplay muted class="video-bg">
      <source src="/images/bg/artvr_80.webm" type="video/webm" />
    </video>
  </div>

  <div
    v-if="props.color"
    class="sticky-bg sticky-bg-overlay"
    :style="{
      backgroundImage: `url(/images/bg/gradient_${props.color}.webp)`,
    }"
  ></div>
</template>

<style>
.sticky-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.sticky-bg-overlay {
  opacity: 0.95;
}

.video-bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
